
import { defineComponent, PropType, ref } from 'vue';
import { deviceType } from './device-type.d';

export default defineComponent({
    props: {
        type: {
            type: Number as PropType<deviceType>,
            required: true
        }
    },
    setup() {
        const deviceIcons = ref({
            0: require('@/assets/image/IconDeviceTypeStair.png'),
            1: require('@/assets/image/IconDeviceTypeDoor.png'),
            2: require('@/assets/image/IconDeviceTypeIndoor.png'),
            3: require('@/assets/image/IconDeviceTypeManagement.png'),
            4: require('@/assets/image/IconDeviceTypeWall.png'),
            50: require('@/assets/image/IconDeviceTypeAccess.png')
        });

        return {
            deviceIcons
        };
    }
});

