
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import { defineComponent, Ref, ref } from 'vue';
import {
    listPagination, RequestData
} from '@/components/common/list';
import { initData } from '../composables/util';

export default defineComponent({
    components: {
        deviceIcon,
        deviceStatus,
        listPagination
    },
    setup() {
        const {
            headers,
            searchKeyList
        } = initData();
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: 'v3/web/single/device/getList',
            param: {
                searchKey: 'MAC',
                searchValue: ''
            }
        });
        return {
            headers,
            updateToList,
            listRequestData,
            searchKeyList
        };
    }
});
