
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        type: {
            type: Number,
            required: true
        }
    }
});
