import { ListHeaderItem } from '@/components/common/list';

const initData = () => {
    const headers: Array<ListHeaderItem> = [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Owner',
        label: WordList.TabelPersonDeviceInHtmlOwner
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'Device Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'BindingTime',
        label: WordList.PersonaldeviceListTanleBindingTime
    }];
    const searchKeyList = [{
        label: WordList.TabelPersonDeviceInHtmlMAC,
        name: 'MAC'
    }, {
        label: WordList.TabelPersonDeviceInHtmlLocation,
        name: 'Location'
    }, {
        label: WordList.MulListUpdateListTanleAccount,
        name: 'Sip'
    }, {
        label: WordList.TabelPersonDeviceInHtmlOwner,
        name: 'Owner'
    }];
    return {
        headers,
        searchKeyList
    };
};

export default null;
export {
    initData
};